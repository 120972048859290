import './ShopProduct.scss'
import React, { Component } from 'react'
import InsHeader from '../../../../components/InsHeader/InsHeader'
import InsNavigation from '../../../../components/InsNavigation/InsNavigation'
import InsButton from '../../../../components/InsButton/InsButton'
import InsCGUBar from '../../../../components/InsCGUBar/InsCGUBar'
import { withTranslation } from 'react-i18next'
import { Link } from "react-router-dom"
import InsSelect from '../../../../components/InsSelect/InsSelect'
import InsLoader from '../../../../components/InsLoader/InsLoader'
import AddToCartModal from '../../../../components/Shop/Product/AddToCartModal'
import { getProduct, addProductToCart, getCart } from '../../../../api/shop'
import Variations from '../../../../components/Shop/Product/Variations'
import images from '../../../../ressources/images'
import { connect } from 'react-redux'
export class ShopProduct extends Component {
  constructor(props) {
    super(props)

    this.state = {
      contentHeight: window.innerHeight,
      cart: {},
      overflow: 'auto',
      product: {},
      quantity: 1,
      showModal: false,
      loader: true,
      openVariation: false,
      selectVariation: false
    }
  }
  componentDidMount() {
    this.getProduct()
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({contentHeight: window.innerHeight})
  }

  // GET PRODUCT DATA
  getProduct = () => {
    let id = this.props.match.params.id
    getProduct(id).then(data => {
      this.setState({
        product: data,
        loader: false
      }, () => {
        this.getUserCart()
      })
    })
  }

  // GET CART DATA
  getUserCart = () => {
    getCart().then(data => {
      this.setState({
        cart: data,
        loader: false
      }, () => {
        this.canAddToCart()
      })
    })
  }

  // CHECK IF USER CAN ADD THE PRODUCT TO THEIR CART
  canAddToCart = () => {
    const {cart, product, quantity} = this.state
    const {account} = this.props
    if(account.hasOrderLessOneHour)
      return true;
    if(account.ordersCount >= 2)
      return true;
    if(account.isValidSector)
      return true
    if ((account.points - cart.total) < (product.points * quantity)) {
      return true
    } else if (product.stock < 1) {
      return true
    }
    return false
  }

  // ADD THE PRODUCT TO CART
  addToCart = () => {
    const {product, quantity} = this.state
    let obj = {}
    obj.quantity = quantity
    this.refs['product_page'].scrollTo(0, 0)
    addProductToCart(product.ean, obj).then(() => {
      this.setState({showModal: true, overflow: 'hidden'})
    })
  }

  // HIDE/SHOW PRODUCT'S DESCRIPTION
  toggleDesc = () => {
    this.refs['product_desc'].classList.toggle('show')
  }

  // SET THE MAXIMUM AMOUNT THAT USER CAN ADD
  selectQty = () => {
    const {cart, product} = this.state
    const {account} = this.props
    let qty = []
    cart.total = (Number.isInteger(cart.total)) ? cart.total : 0

    for(let i = 1; i <= Math.floor((account.points - cart.total)/product.points) && i <= product.stock; i++) {
      qty.push({value:`${i}`,label:`${i}`})
    }
    return qty
  }

  // SHOW/HIDE VARIATIONS
  toggleVariation = () => {
    this.refs['product_page'].scrollTo(0, 0)
    this.setState({openVariation: !this.state.openVariation})
  }

  // SET DATA FROM SELECTED VARIATION
  selectVariation = e => {
    const {product} = this.state
    let value = e.currentTarget.textContent
    let valueEan = e.currentTarget.dataset.ean
    let valueStock = e.currentTarget.dataset.stock
    let valueColor = e.currentTarget.dataset.color
    let valuePoints = e.currentTarget.dataset.points


    this.setState({
      openVariation: !this.state.openVariation,
      product: {
        ...product,
        color: valueColor,
        ean: valueEan,
        value_label: value,
        stock: valueStock,
        points: valuePoints
      },
      selectVariation: true
    })
  }

  // SET THE AMOUNT TO ADD
  handleSelectQty = input => e => {
    this.setState({quantity: e.target.value}, () => {
      this.canAddToCart()
    })
  }

  render() {
    const {contentHeight, loader, overflow, openVariation, product, showModal} = this.state
    const { brand, color, description, name, pictures, points, stock, value_label, variations} = product
    const {t, account} = this.props

    let qty = this.selectQty()
    let contentStyle = {height: contentHeight - 120, overflow: overflow};
    if(account.isValidSector)
      contentStyle = {height: contentHeight - 120, overflow: overflow, opacity: 0.9};

    let limitOrderMessage = "";
    if(account.ordersCount >= 2)
      limitOrderMessage = "Vous avez atteint la limite de commandes de l'année";
    else if(account.ordersCount == 1)
      limitOrderMessage = "Il ne vous reste plus qu'une commande pour l'année";
    return (
      <>
        <InsHeader/>
          <div className="product_page" ref='product_page' style={contentStyle}>
            {loader ?
              <InsLoader/>
            :
              <>
                <Link
                    to={{ pathname: '/shop', state: { transitionName: "slide_out" }}}
                    className="back_link"
                  >
                </Link>

                <div className="product_infos">
                  <div className="product_brand">
                    <img src={brand.logoNoir} alt=""/>
                  </div>

                  <div className={stock > 0 ? "product_price" : "product_price disabled"}>
                    {points}
                  </div>

                  <div className="product_picture">
                    <img src={pictures.length ? process.env.REACT_APP_URL + pictures[0].name.name_333 : images.we_are_lol_black} alt={name}/>
                  </div>

                  <div className="product_name_wrapper">
                    <div className="product_name product_name-bold">
                      {name}
                    </div>
                    {!!variations.length &&
                      <div className="product_variations">
                        {color ? value_label : <div className="container">{value_label}</div>}
                      </div>
                    }
                  </div>

                  <div className="desc_block" onClick={this.toggleDesc}>
                    <div className="desc">{t('shop:product_desc')}</div>
                    <div className="product_desc" ref="product_desc" dangerouslySetInnerHTML={{ __html : description }} />
                  </div>
                </div>

                <div className="select_wrapper">
                  {!!variations.length &&
                    <>
                      <div className="select_block">
                        <div className="select_variations" onClick={this.toggleVariation}>
                          {variations[0].color
                          ?
                            <div className="selected">
                              <div className="color" style={{backgroundColor: color}}></div>
                              <div className="label">{value_label}</div>
                            </div>
                          :
                            <div className="selected container">
                              <div className="label">
                                {t('shop:container')}
                              </div>
                              <div className="value">
                                {value_label}
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                      {openVariation &&
                        <Variations
                          variations={variations}
                          selectVariation={this.selectVariation}
                          close={this.toggleVariation}
                        />
                      }
                    </>
                  }

                  {stock > 0 &&
                    <div className={!qty.length ? "select_block qty disabled" : "select_block qty"}>
                      <div className="qty_holder">{t('shop:quantity')}</div>
                      <InsSelect
                        className="qty_select"
                        name="qty"
                        onChange={this.handleSelectQty()}
                        values={qty}
                        dir='rtl'
                        disabled={!qty.length}
                      />
                    </div>
                  }

                </div>

                <InsButton
                  onClick={this.addToCart}
                  type="outline"
                  disabled={this.canAddToCart()}
                  text={t('shop:add_to_cart')}
                />
                {limitOrderMessage != "" &&
                    <div className="limit_order_message">
                      {limitOrderMessage}
                    </div>
                }
                {account.hasOrderLessOneHour &&
                  <div className="limit_one_hour_order_message">
                    {t('shop:limit_order_message_less_one_hour')}
                  </div>
                }
                <InsCGUBar/>

                {showModal && <AddToCartModal/>}
              </>
            }
          </div>
        <InsNavigation/>
      </>
    )
  }
}
//extract data from the redux store
const mapStateToProps = (state) => ({
  account: state.account
})

export default (connect(mapStateToProps)(withTranslation()(ShopProduct)))
